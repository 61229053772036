import Users from '@/views/extensions/users/Users.vue'

const routerView = () => ({
  render(h) {
    return h("router-view");
  }
});

export default [
  // {
  //   path: '/users',
  //   name: 'users',
  //   component: () => import('@/views/extensions/users/Users.vue'),
  //   // meta: {
  //   //   resource: 'USERS',
  //   //   action: 'read',
  //   // },
  // },

  {
    path: '/users',
    name: 'users',
    component: Users,
    // meta: {
    //   pageTitle: 'Jinoiy Sud',
    //   breadcrumb: [
    //     {
    //       text: 'Jinoiy Sud',
    //       active: true,
    //     },
    //   ],
    // },
  },
  {
    path: '/category',
    name: 'category',
    component: () => import('@/views/extensions/category/category.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    },
  },
  {
    path: '/advice-list',
    name: 'advice',
    component: () => import('@/views/extensions/advice/advice-blog.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    path: '/advice',
    name: 'advice',
    component: () => import('@/views/extensions/advice/advice.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    },
  },
  {
    path: '/development',
    name: 'development',
    component: () => import('@/views/extensions/development/development.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    path: '/development-list',
    name: 'development-list',
    component: () => import('@/views/extensions/development/development-list.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    path: '/game',
    name: 'game',
    component: () => import('@/views/extensions/game/game.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
  {
    path: '/game-blog',
    name: 'game-blog',
    component: () => import('@/views/extensions/game/game-blog.vue'),
    meta: {
      resource: 'USERS',
      action: 'read',
    }},
]
