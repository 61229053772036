<template>
  <router-view></router-view>
<!--  <b-row-->
<!--    v-if="$route.meta.breadcrumb || $route.meta.pageTitle"-->
<!--    class="content-header"-->
<!--  >-->

<!--    &lt;!&ndash; Content Left &ndash;&gt;-->
<!--    <b-col-->
<!--      class="content-header-left mb-2"-->
<!--      cols="12"-->
<!--      md="9"-->
<!--    >-->
<!--      <b-row class="breadcrumbs-top">-->
<!--        <b-col cols="12">-->
<!--          <h2 class="content-header-title float-left pr-1 mb-0">-->
<!--            {{ $route.meta.pageTitle }}-->
<!--          </h2>-->
<!--          <div class="breadcrumb-wrapper">-->
<!--            <b-breadcrumb>-->
<!--              <b-breadcrumb-item to="/">-->
<!--                <feather-icon-->
<!--                  icon="HomeIcon"-->
<!--                  size="16"-->
<!--                  class="align-text-top"-->
<!--                />-->
<!--              </b-breadcrumb-item>-->
<!--              <b-breadcrumb-item-->
<!--                v-for="item in $route.meta.breadcrumb"-->
<!--                :key="item.text"-->
<!--                :active="item.active"-->
<!--                :to="item.to"-->
<!--              >-->
<!--                {{ item.text }}-->
<!--              </b-breadcrumb-item>-->
<!--            </b-breadcrumb>-->
<!--          </div>-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--    </b-col>-->

<!--    &lt;!&ndash; Content Right &ndash;&gt;-->
<!--&lt;!&ndash;    <b-col&ndash;&gt;-->
<!--&lt;!&ndash;      class="content-header-right text-md-right d-md-block d-none mb-1"&ndash;&gt;-->
<!--&lt;!&ndash;      md="3"&ndash;&gt;-->
<!--&lt;!&ndash;      cols="12"&ndash;&gt;-->
<!--&lt;!&ndash;    >&ndash;&gt;-->
<!--&lt;!&ndash;      <b-dropdown&ndash;&gt;-->
<!--&lt;!&ndash;        variant="link"&ndash;&gt;-->
<!--&lt;!&ndash;        no-caret&ndash;&gt;-->
<!--&lt;!&ndash;        toggle-class="p-0"&ndash;&gt;-->
<!--&lt;!&ndash;        right&ndash;&gt;-->
<!--&lt;!&ndash;      >&ndash;&gt;-->

<!--&lt;!&ndash;        <template #button-content>&ndash;&gt;-->
<!--&lt;!&ndash;          <b-button&ndash;&gt;-->
<!--&lt;!&ndash;            v-ripple.400="'rgba(255, 255, 255, 0.15)'"&ndash;&gt;-->
<!--&lt;!&ndash;            variant="primary"&ndash;&gt;-->
<!--&lt;!&ndash;            class="btn-icon"&ndash;&gt;-->
<!--&lt;!&ndash;          >&ndash;&gt;-->
<!--&lt;!&ndash;            <feather-icon icon="SettingsIcon" />&ndash;&gt;-->
<!--&lt;!&ndash;          </b-button>&ndash;&gt;-->
<!--&lt;!&ndash;        </template>&ndash;&gt;-->

<!--&lt;!&ndash;        <b-dropdown-item :to="{ name: 'apps-todo' }">&ndash;&gt;-->
<!--&lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--&lt;!&ndash;            icon="CheckSquareIcon"&ndash;&gt;-->
<!--&lt;!&ndash;            size="16"&ndash;&gt;-->
<!--&lt;!&ndash;          />&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="align-middle ml-50">Todo</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <b-dropdown-item :to="{ name: 'apps-chat' }">&ndash;&gt;-->
<!--&lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--&lt;!&ndash;            icon="MessageSquareIcon"&ndash;&gt;-->
<!--&lt;!&ndash;            size="16"&ndash;&gt;-->
<!--&lt;!&ndash;          />&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="align-middle ml-50">Chat</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <b-dropdown-item :to="{ name: 'apps-email' }">&ndash;&gt;-->
<!--&lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--&lt;!&ndash;            icon="MailIcon"&ndash;&gt;-->
<!--&lt;!&ndash;            size="16"&ndash;&gt;-->
<!--&lt;!&ndash;          />&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="align-middle ml-50">Email</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->

<!--&lt;!&ndash;        <b-dropdown-item :to="{ name: 'apps-calendar' }">&ndash;&gt;-->
<!--&lt;!&ndash;          <feather-icon&ndash;&gt;-->
<!--&lt;!&ndash;            icon="CalendarIcon"&ndash;&gt;-->
<!--&lt;!&ndash;            size="16"&ndash;&gt;-->
<!--&lt;!&ndash;          />&ndash;&gt;-->
<!--&lt;!&ndash;          <span class="align-middle ml-50">Calendar</span>&ndash;&gt;-->
<!--&lt;!&ndash;        </b-dropdown-item>&ndash;&gt;-->
<!--&lt;!&ndash;      </b-dropdown>&ndash;&gt;-->
<!--&lt;!&ndash;    </b-col>&ndash;&gt;-->
<!--  </b-row>-->
</template>

<script>
import {
  BBreadcrumb, BBreadcrumbItem, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BButton,
  },
}
</script>
