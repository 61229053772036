import dashboard from '@/router/routes/dashboard'
import formsTable from '@/router/routes/forms-tables'
import others from '@/router/routes/others'

const routeList =[
  // ...apps,
  ...dashboard,
  // ...pages,
  // ...chartsMaps,
  ...formsTable,
  // ...uiElements,
  ...others,

];
export default routeList;
// export default authGuard =>
//     authGuard ? addAuthGuard(null, navigation, authGuard) : navigation;
