import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import axios from 'axios'
import useJwt from '@/auth/jwt/useJwt'
import routeList from '@/router/routeList'
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue'
import pages from './routes/pages'

Vue.use(VueRouter)
const authGuard = async (to, from, next) => {
  try {
    if (typeof next !== 'function') return
    const accessToken = useJwt.getToken()
    if (!accessToken) {
      next('/login')
      return
    }
    const { data } = await axios.get('https://bolajon.mtsh.uz/api/user/internal-me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
    if (accessToken && typeof data !== 'string') {
      // store.commit('setUserData', Object.assign(data, { name: data['employeeShortNameCyrillic'] }))
      //
      // if(data.roles.length!=0){
      //   if(data.roles[0].hasOwnProperty('permissions') && data.roles[0]["permissions"].length!=0){
      //     store.commit("setPermissions", { static_permissions: data.roles[0]["permissions"] });
      //   }
      // }else{
      //   next('/error-404')
      // }
      next()
    } else {
      next('/login')
    }
  } catch (e) {
    console.log('Error:', e)
  }
}
const routes = [
  ...pages,
  {
    path: '/',
    name: 'AppBreadcrumb',
    component: AppBreadcrumb,
    redirect: '/',
    beforeEnter: authGuard,
    children: routeList,
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  props: { default: true },
})
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
